import { Link } from 'react-router-dom'
function Footer(){
    return(
        <footer className="footer_1 text-center text-lg-start text-muted mt-4">

 <div className="topft_12">
  <div className="topft_1 container-fluid d-flex">

    <div className="me-5">
      <h6>Ready To Discuss Your Project?</h6>
      <p>With our expertise and your unique perspective, let's discuss your project and exceed expectations.</p>
    </div>





      <div className="srch-1">
      <button className="rqst_1"><Link to="/contact">Request A Quote</Link></button>
  </div>


  </div>
</div>



  <section className="">
    <div className="container text-center text-md-start mt-5">

      <div className="row mt-3">


        <div className="ft_1 col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

          <p className="ftrtitle_1 text-capitalize fw-bold mb-4">
           Useful Links
          </p>
          <p>
          <Link className="text-reset" to="/">Home</Link>
          </p>
          <p>
          <Link className="text-reset" to="/about">About Us</Link>
          </p>
          <p>
          <Link className="text-reset" to="portfolio">Portfolio</Link>
          </p>
          <p>
          <Link className="text-reset" to="services">Services</Link>
          </p>
          <p>
          <Link className="text-reset" to="contact">Contact</Link>
          </p>
        </div>



        <div className="ft_1 col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">

          <p className="ftrtitle_1 text-capitalize fw-bold mb-4">
            Our Services
          </p>
          <p>
            <Link to="/web-design" className="text-reset">Web Designing</Link>
          </p>
           <p>
            <Link to="/web-development" className="text-reset">Web Development</Link>
          </p>
          <p>
            <Link to="/digital-marketing-agency" className="text-reset">Digital Marketing</Link>
          </p>
           <p>
            <Link to="/graphic-design" className="text-reset">Graphic Designing</Link>
          </p>
           <p>
            <Link to="/website-maintenance" className="text-reset">Website Maintenance</Link>
          </p>
           <p>
            <Link to="/website-template" className="text-reset">Template</Link>
          </p>
           <p>
            <Link to="/website-analytics" className="text-reset">Website Analytics</Link>
          </p>
           <p>
            <Link to="/ppc" className="text-reset">PPC</Link>
          </p>
          <p>
            <Link to="/seo" className="text-reset">SEO</Link>
          </p>
        </div>



        <div className="ft_1 col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

          <p className="ftrtitle_1 text-capitalize fw-bold mb-4">Contant Us</p>
          <p>D-151, Phase-8, Industrial Area, Sector 71, Sahibzada Ajit Singh Nagar, Punjab 160071 </p>
          <p className="phn_1" ><Link to="tel:+916239452423">

           <span>Phone:</span> +91 6239452423</Link>
          </p>
          <p className="phn_1">  <Link to="mailto:uniworkitsolution@gmail.com"> <span>Email:</span> uniworkitsolution@gmail.com</Link></p>
        </div>





 <div className="ft_1 col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">

          <p className="ftrtitle_1 text-capitalize fw-bold mb-4">
            About Uniwork Technologies
          </p>
          <p>
          Uniwork Technologies is a prominent company specializing in a wide range of services, including Website Design & Development, Digital Marketing and more. Our experienced team delivers tailored solutions to clients around the world.
          </p>

          <ul className="scl_1">
            <li><Link to="https://www.facebook.com/uniworkteam" target="_blank"><img src="images/fb.webp" alt="Facebook"/></Link></li>
            <li><Link to="https://www.instagram.com/uniworktechnologies/" target="blank"><img src="images/inst.webp" alt="Instagram"/></Link></li>
            <li><Link to="https://www.linkedin.com/company/77836743/admin/feed/posts/" target="_blank"><img src="images/lnkdn.webp" alt="LinkedIn"/></Link></li>

          </ul>
        </div>



      </div>

    </div>
  </section>



  <div className="cprgt_1 text-center p-4" style={{color: '#4E4E4E' }}>

    <Link className="text-reset" to="#">© Copyright 2025 | Uniwork Technologies. All Rights Reseverd</Link>
  </div>


</footer>

    );
}

export default Footer;